import React, { Component } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import CSVReader from "react-csv-reader";
import PhotoSpot from "./photoSpot";

class PhotoSpots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startIndex: 1,
      limit: 100,
      datas: [],
      csvData: [],
      showMap: false,
      showMapDetail: null
    };
  }

  renderMapModal() {
    if (this.state.showMapDetail) {
      console.log(this.state.showMapDetail);
      let lon = parseFloat(this.state.showMapDetail.lon);
      let lat = parseFloat(this.state.showMapDetail.lat);

      let lon_radius = 0.0042889;

      let lat_radius = 0.0021934;

      var src =
        "https://www.openstreetmap.org/export/embed.html?bbox=" +
        (lon - lon_radius) +
        "%2C" +
        (lat + lat_radius) +
        "%2C" +
        (lon + lon_radius) +
        "%2C" +
        (lat - lat_radius) +
        "&layer=mapnik&marker=" +
        lat +
        "%2C" +
        lon;

      console.log("src:" + src);

      return (
        <Modal
          size="lg"
          show={this.state.showMap}
          onHide={() => {
            this.setState({ showMap: false });
          }}
        >
          <Modal.Body>
            <iframe
              width="760"
              height="600"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={src}
              style={{ border: "1px solid grey" }}
            ></iframe>
            <br />
          </Modal.Body>
        </Modal>
      );
    } else {
      return "";
    }
  }

  renderTableData() {
    return this.state.datas.map(data => {
      if (data[0]) {
        return (
          <Col lg="6">
            <PhotoSpot key={data[0]} data={data} showMap={this.showMap} />
          </Col>
        );
      } else {
        return "";
      }
    });
  }

  showMap = data => {
    console.log(data);

    this.setState(
      {
        showMapDetail: {
          lat: data.lat,
          lon: data.lon
        }
      },
      () => {
        this.setState({ showMap: true });
      }
    );
  };

  handleNextPage = () => {
    if (this.state.csvData.length > 0) {
      console.log(this.state.startIndex);
      this.setState(
        {
          startIndex: this.state.startIndex + this.state.limit
        },
        () => {
          this.setState({
            datas: this.state.csvData.slice(
              this.state.startIndex,
              this.state.startIndex + this.state.limit
            )
          });
        }
      );
    }
  };

  handlePrevPage = () => {
    if (this.state.csvData.length > 0) {
      console.log(this.state.startIndex);
      this.setState(
        {
          startIndex: this.state.startIndex - this.state.limit
        },
        () => {
          this.setState({
            datas: this.state.csvData.slice(
              this.state.startIndex,
              this.state.startIndex + this.state.limit
            )
          });
        }
      );
    }
  };

  renderPagination() {
    if (this.state.csvData.length > 0) {
      let endIndex = this.state.startIndex + this.state.datas.length - 1;
      return (
        <div className="text-right">
          <span className="align-middle pr-2">
            {"Current range: " + this.state.startIndex + " ~ " + endIndex}
          </span>
          <Button
            size="sm"
            variant="warning"
            className="m-2"
            onClick={this.handlePrevPage}
          >
            <i className="fas fa-arrow-left"></i>
          </Button>
          <Button size="sm" onClick={this.handleNextPage}>
            <i className="fas fa-arrow-right"></i>
          </Button>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    return (
      <Container>
        <h3>Photo spot analyse</h3>

        {this.renderMapModal()}
        <CSVReader
          onFileLoaded={results => {
            this.setState({
              csvData: results,
              datas: results.slice(
                this.state.startIndex,
                this.state.startIndex + this.state.limit
              )
            });
          }}
        />
        <br />
        {this.renderPagination()}
        <Row>{this.renderTableData()}</Row>
        {this.renderPagination()}
      </Container>
    );
  }
}

export default PhotoSpots;
