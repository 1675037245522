import React, { Component } from "react";

import { Col, Row, Button, Modal } from "react-bootstrap";

class PhotoSpot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoUrl: "",
      lat: this.props.data[14],
      lon: this.props.data[15]
    };

    let url = this.props.data[9].split(" ");

    let path = "photos/" + url[0] + "/medium/" + url[1];

    this.fetchPhotoUrl(path, url => {
      this.setState({ photoUrl: url });
    });
  }

  fetchPhotoUrl(path, callback) {
    fetch(process.env.REACT_APP_API_URL + "/create_presigned_url?key=" + path, {
      method: "GET",
      mode: "cors"
    }).then(response => {
      if (response.status !== 200) {
      } else {
        response.json().then(result => {
          callback(result.url);
        });
      }
    });
  }

  render() {
    return (
      <Row className="border-top mt-3">
        <Col>
          <span>
            <strong>{this.props.data[3]}</strong>
          </span>

          <div>
            <a
              href={"https://iko-yo.net/facilities/" + this.props.data[2]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ikoyo
            </a>

            <br />

            <Button
              variant="link"
              className="m-0 p-0"
              onClick={() =>
                this.props.showMap({
                  lat: this.props.data[14],
                  lon: this.props.data[15]
                })
              }
            >
              Ikoyo location
            </Button>
            <br />
            <Button
              className="m-0 p-0"
              variant="link"
              onClick={() =>
                this.props.showMap({
                  lat: this.props.data[16],
                  lon: this.props.data[17]
                })
              }
            >
              Photo location
            </Button>
          </div>
          <div>
            <span>Photo ID: {this.props.data[8]}</span>
          </div>
          <div>
            <span>Distance: {this.props.data[7]}</span>
          </div>
          <div>
            <span>Created: {this.props.data[13]}</span>
          </div>

          {this.props.data[5].split(" ").map((item, index) => {
            return <div key={index}>{item}</div>;
          })}
        </Col>
        <Col>
          <img width="300" src={this.state.photoUrl} alt="" />
        </Col>
      </Row>
    );
  }
}

export default PhotoSpot;
